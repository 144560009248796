<template>
  <main v-if="!pageActive">
    <header class="view-user-loyalty__header">
      <h3 class="view-user-loyalty__title">{{ loyaltyTitle }}</h3>
      <h5 class="view-user-loyalty__description">{{ loyaltyDescription }}</h5>
    </header>

    <!-- Content -->
    <section class="view-user-loyalty__body">
      <div
        class="view-user-loyalty__articles"
        v-if="placeholderContent"
        v-html="placeholderContent"
      ></div>

      <div
        class="view-user-loyalty__articles"
        v-else>
        <h6 class="view-user-loyalty__articlestitle">{{ $t('resto.loyalty_program_how') }}</h6>

        <article
          class="view-user-loyalty__article"
          :key="$basil.uniqId(i)"
          v-for="(item, i) in explanations" >
          <div class="view-user-loyalty__articletitle" v-if="item.title">{{ item.title }}</div>
          <div class="view-user-loyalty__articlebody" v-if="item.description">{{ item.description }}</div>
        </article>
      </div>

      <aside class="view-user-loyalty__figure">
        <figure>
          <ui-img
            alt=""
            class="view-user-loyalty__image"
            :src="imgURL"
          />
        </figure>
      </aside>
    </section>

    <!-- Signin and up -->
    <ui-logger class="view-user-loyalty__body view-user-loyalty__actions"/>
  </main>

  <main v-else>
    <loyalty-page />

    <modal-wallet-pass
      :url="walletUrl"
      :visible="walletVisible"
      @close="() => walletVisible = false"
      v-if="walletVisible"
    />
  </main>
</template>

<script>
import { mapState } from 'vuex'
import Config from '@/config'
import UiImg from '@/components/ui/img'

import UiLogger from '../../components/ui/logger'
import LoyaltyPage from './page.vue'

export default {
  name: 'ViewLoyaltyExplanation',

  components: {
    UiImg,
    UiLogger,
    LoyaltyPage
  },

  inject: [
    '$core',
    '$embed',
    '$loyalty',
    '$user',
  ],

  data() {
    return {
      useLoyaltyPage: true,
      walletVisible: false,
      walletUrl: null
    }
  },

  computed: {
    ...mapState({
      i18n: state => state.bootstrap.i18n,
    }),

    imagePlaceholder() {
      return this.images.find(p => p.field_name === 'banner')
    },

    images() {
      return this.$basil.get(this.loyalty, 'images', []) || []
    },

    imgURL() {
      return this.imagePlaceholder
        ? this.$basil.get(this.imagePlaceholder, 'url', null)
        : `${Config.cdn}statics/images/gift.png`
    },

    loyalty() {
      return this.$basil.get(this.$loyalty, 'program', null)
    },

    loyaltyName() {
      return this.$basil.get(this.loyalty, 'name', null)
    },

    loyaltyDescription() {
      return !this.$basil.isNil(this.pageSubtitle)
        ? this.pageSubtitle
        : this.$t('resto.loyalty_program_description')
    },

    loyaltyTitle() {
      return !this.$basil.isNil(this.pageTitle)
        ? this.pageTitle
        : this.$t('resto.loyalty_program_title', { name: this.loyaltyName })
    },

    hasLoyalty() {
      let excludedShopIds = this.$basil.get(this.$loyalty, 'program.excludedShopIds', [])
      let isShopExcluded = false
      
      if(!this.$basil.isNil(excludedShopIds) && !this.$basil.isEmpty(excludedShopIds)) {
        isShopExcluded = !this.$basil.isNil(excludedShopIds.find(e => e === this.$basil.get(this.$embed, 'shop.model.id')))
      }

      return [
        !this.$basil.isNil(this.$basil.get(this.$loyalty, 'program', null)),
        !this.isModeTt,
        !isShopExcluded
      ].filter(r => r === false).length === 0
    },

    explanations() {
      let ret = this.$t('resto.loyalty_explanations')
      let items =  ret.split('}')

      items = items.map(i => {
        let title = this.$basil.get(i.split(']'), '0')
        title = title.replace('[', '')

        let descr = this.$basil.get(i.split('{'), '1')
        return {
          title,
          description: descr
        }
      })

      return items
    },

    pageActive() {
      return (this.loyalty?.pageActive ?? false) && (this.loyalty?.pageContent ?? null) != null && (this.loyalty?.pageStyle ?? null) != null
    },

    pageSubtitle() {
      let ret = [
        this.$basil.get(this.loyalty, `pageSubtitle.${this.userLang}`),
        this.$basil.get(this.loyalty, `pageSubtitle.all`),
      ]
      ret = ret.filter(r => !this.$basil.isNil(r))
      return ret.length > 0 ? ret.find(r => !this.$basil.isNil(r)) : null
    },

    pageTitle() {
      let ret = [
        this.$basil.get(this.loyalty, `pageTitle.${this.userLang}`, null),
        this.$basil.get(this.loyalty, `pageTitle.all`, null),
      ]
      ret = ret.filter(r => !this.$basil.isNil(r))
      return ret.length > 0 ? ret.find(r => !this.$basil.isNil(r)) : null
    },

    placeholders() {
      return this.$basil.get(this.loyalty, 'placeholders', []) || [];
    },

    placeholderContent() {
      let content = this.placeholders.find(p => p.key === 'content')

      return this.$basil.isNil(content) ? null :
        this.$basil.get(content, `content.${this.userLang}`, this.$basil.get(content, 'content.all'))
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userLang() {
      return this.$basil.get(this.user, 'lang', this.i18n.locale.lang.toString())
    },
  },

  mounted() {
    if(!this.hasLoyalty) {
      this.$router.push({ name: 'sayl-front-user.home' })
    }
  }
}
</script>
