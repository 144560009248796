<template>
  <div
    class="user__page"
    id="sayl-loyalty-page-resto"
    v-if="!iLoading"
  ></div>

  <div
    class="user__loader"
    v-else>
    <ui-loader />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Config from '@/config'
import { loadScript } from '@/helpers/loadscript.js'

export default {
  name: 'LoyaltyPage',

  inject: [
    '$core',
    '$challenge',
    '$loyalty',
    '$referral',
    '$user'
  ],

  data() {
    return {
      iLoading: true,
      history: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      referralHistory: [],
      referralPagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      loyalty: null,
      key: 1,
      baseReferralUrl: null,
    }
  },

  computed: {
    ...mapGetters({
      oat: 'bootstrap/oat',
    }),

    dataset() {
      return {
        cards: this.userCards,
        content: this.$basil.get(this.program, 'pageContent'),
        display: this.programDisplayRules,
        points: this.$basil.get(this.program, 'points'),
        referral: this.referral,
        rules: this.$basil.get(this.program, 'rules'),
        style: this.$basil.get(this.program, 'pageStyle'),
        tiers: this.tiers,
        tierSettings: this.tiersSettings,
        user: this.loyaltyUser,
        vouchers: this.vouchers,
      }
    },
    
    hasChallenges() {
      return this.$basil.get(this.oat, 'is_challenge_enabled', false) &&
        this.$basil.get(this.$challenge, 'active', false)
    },

    hasReferral() {
      return !this.$basil.isNil(this.referral)
    },

    isLogged() {
      return this.user.anonymous === false
    },

    loyaltyUser() {
      return this.isLogged ?
        {
          firstname: this.$basil.get(this.user, 'firstname'),
          lastname: this.$basil.get(this.user, 'lastname'),
          points: this.$basil.get(this.user, 'balance.points'),
          historyHasMore: this.pagination.total > this.pagination.currentPage * this.pagination.pageSize,
          history: this.history.map((h) => {
            return {
              amount:  parseFloat(h.amount, 10).toFixed(2),
              key: h.id,
              title: this.getLabel(h.message),
              date: h.created,
              metadata: this.getMeta(h)
            }
          }),
          birthdate: this.$basil.get(this.user, 'birthdate', null) ,
          tier: this.$basil.get(this.user, 'loyaltyTierId', null)
        } : null
    },

    program() {
      return this.$loyalty.program
    },

    programDisplayRules() {
      return {
        digitalCards: this.$basil.get(this.program, 'allowDigitalCards', false),
        physicalCards: this.$basil.get(this.program, 'allowPhysicalCards', false),
        points: this.$basil.get(this.program, 'allowPointsRedemption', false),
        vouchers: this.$basil.get(this.program, 'allowVouchersRedemption', false),
        referral: this.hasReferral && this.$basil.get(this.$referral, 'program.active', false),
        tiers: this.$basil.get(this.program, 'tiers.length', 0) > 0,
      }
    },

    referral() {
      let ret = null
      let r = this.$referral.program

      if(!this.$basil.isNil(r)) {
        ret = {
          linkUtm: this.$basil.get(r, 'linkUtm'),
          referrerRewards: this.$basil.get(r, 'referrerRewards'),
          referringRewards: this.$basil.get(r, 'referringRewards'),
          user: this.userReferral ? { code: (this.userReferral?.code ?? null) } : null,
          link: this.baseReferralUrl,
          history: this.referralHistory.map(rh => {
            let reward = this.$basil.get(rh, 'generatedEntity.type')
            if(reward === 'nft_transfer') {
              reward = 'nft'
            }

            if(reward === 'credit') {
              reward = 'loyalty'
            }

            return {
              key: rh.id,
              date: rh.created,
              title: this.$t('resto.referral_type_' + rh.type),
              reward: reward,
              metadata: this.getMeta(rh)
            }
          }),
          historyHasMore: this.referralPagination.total > this.referralPagination.currentPage * this.referralPagination.pageSize,
        }
      }
      return this.key && ret
    },

    scriptUrl() {
      return this.$basil.get(Config, 'loyalty.page.url', 'https://cdn-apps.sayl.cloud/loyalty-page/latest/index.js')
    },

    tiers() {
      let ret = this.$basil.get(this.program, 'tiers', []) || []
      return ret.map(t => {
        return {
          amount: t.amount || null,
          description: t.description, 
          key: t.id, 
          name: t.name,
          rewards: t.rewards.map(r => {
            return {
              description: r.description, 
              name: r.name,
              type: r.type
            }
          })
        }
      })
    },

    tiersSettings() {
      return this.$basil.get(this.program, 'tierSettings', {})
    },

    user() {
      return this.$user.user
    },

    userCards() {
      let ret = this.$basil.get(this.$loyalty, 'cards', [])
      return ret.map(r => {
        return  {
          key: r.id,
          token: r.token,
          status: r.status,
          type: r.type,
        }
      })
    },

    userReferral() {
      return this.key && this.$referral.userReferral
    },

    vouchers() {
      return this.program.redeemableVouchers.map((v) => {
        return {
          title: this.$basil.get(v, 'template.name'),
          key: this.$basil.get(v, 'voucherId'),
          points: this.$basil.get(v, 'requiredPoints'),
          validityPeriod: this.$basil.get(v, 'validityPeriod'),
          type: this.$basil.get(v, 'template.type'),
        }
      })
    },

    walletHref() {
      return this.$basil.get(Config, 'transports.cp.api') + `user/card`//?embed_id=${id}`
    }
  },

  methods: {
    getCards() {
      return new Promise((resolve, reject) => {
        let prom = this.isLogged ?
          this.$loyalty.getCards.bind(this.$loyalty, { limit: -1, page: 1 }) :
          Promise.resolve.bind(Promise)

        prom.apply()
          .then(resolve)
          .catch(reject)
      })
    },

    getHistory(page = 1) {
      return new Promise((resolve, reject) => {
        this.$loyalty.getBalanceHistory({ limit: this.pagination.pageSize, page: page })
          .then(({ balance, pagination }) => {
            if(page === 1) {
              this.history = []
            }
            this.history.push(...balance._items)
            this.pagination = {
              currentPage: pagination.current_page,
              pageSize: pagination.per_page,
              total: pagination.total
            }
            resolve()
          })
          .catch((e) => reject(e))
      })
    },

    getHistoryReferral(page = 1) {
      return new Promise((resolve, reject) => {
        this.$referral.getHistory({ limit: this.referralPagination.pageSize, page: page })
          .then(({ history, pagination }) => {
            if(page === 1) {
              this.referralHistory = []
            }
            this.referralHistory.push(...history.items)
            this.referralPagination = {
              currentPage: pagination.current_page,
              pageSize: pagination.per_page,
              total: pagination.total
            }
            resolve()
          })
          .catch((e) => reject(e))
      })
    },

    getLabel(l) {
      let ret = l

      let valid = [
        'paid_order',
        'redeemed_credits',
        'subscribed_reward',
        'in_store_reward',
        'loyalty_trade',
        'challenge_reward'
      ]

      if(valid.includes(l)) {
        ret = this.$t(`resto.user_balance_message_${l}`)
      }

      return ret
    },

    getMeta(h) {
      let ret = []

      if(h.message === 'loyalty_trade' && h.voucher != null) {
        ret.push({
          key: 'code',
          value: this.$basil.get(h, 'voucher.code', null)
        })
      }

      if(h.referralId != null) {
        if(h.credit) {
          ret.push({
            key: 'credit',
            value: this.$basil.get(h, 'credit.amount', null)
          })
        }

        if(h.voucher) {
          ret.push({
            key: 'code',
            value: this.$basil.get(h, 'voucher.code', null)
          })
        }
      }

      return ret
    },

    getReferralLink(action) {
      return new Promise((resolve, reject) => {
        let r = this.$referral.program
        const baseURL = new URL(`${window.location.origin}${Config.publicPath}`)
        const searchParams = new URLSearchParams()
        searchParams.set('utm_source', this.$basil.get(r, 'linkUtm.source', null))
        searchParams.set('utm_medium', this.$basil.get(r, 'linkUtm.medium', null))
        searchParams.set('utm_campaign', this.userReferral ? (this.userReferral?.code ?? null) : null)
        searchParams.set('utm_content', action)
        baseURL.search = searchParams.toString()
        this.$user.shorten({ url: baseURL.toString() })
          .then((url) => resolve(url))
          .catch((e) => reject(e))
      })
    },

    initReferral() {
      return new Promise((resolve, reject) => {
        if(this.hasReferral && this.isLogged) {
          this.$referral.findOne()
            .then(() => this.getHistoryReferral())
            .then(() => this.getReferralLink('input'))
            .then((link) => {
              this.baseReferralUrl = link
              resolve()
            })
            .catch((e) => reject(e))
        } else {
          resolve()
        }
      })
    },

    reset() {
      this.iLoading = true
      this.getHistory()
        .then(() => this.getCards())
        .then(() => this.initReferral())
        .catch((e) => console.error(e))
        .finally(() => {
          this.iLoading = false
          setTimeout(() => loadScript(this.scriptUrl, this.scriptLoaded), 100)
        })
    },

    scriptLoaded() {
      this.loyalty = window.SaylLoyaltyPage.init(
        this.dataset,
        {
          context: document.getElementById('sayl-loyalty-page-resto'),
          container: document.getElementById('sayl-loyalty-page-resto'),
          shadow: false,
          hasHeight: false,
        }
      )

      this.loyalty.way = null

      if(this.loyalty) {
        this.loyalty.toggleWays('referral', this.programDisplayRules.referral)
        this.loyalty.toggleWays('challenge', this.hasChallenges)
        this.loyalty.toggleWays('birthday', this.$basil.get(this.program, 'rules.birthdayReward', 0) > 0)
        this.loyalty.toggleWays('tiers', this.$basil.get(this.program, 'allowTiers', false) && this.$basil.get(this.program, 'tiers.length', 0) > 0)
        this.loyalty.toggleWays('registration', this.$basil.get(this.program, 'rules.welcomeReward', 0) > 0)

        this.loyalty.registerCallback('redeem', (voucher, done) => {
          this.$loyalty.tradeVoucher({ voucherId: voucher.key })
            .then((r) => {
              voucher.code = r.code
              voucher.redeemed = true
              // done()
              return this.$user.view()
            })
            .then(() => this.getHistory(1))
            .then(() => this.loyalty.set('user', this.loyaltyUser))
            .catch((e) => voucher.error = this.$t(`resto.voucher_${e?.response?.data?.message ?? 'unknown_error'}`))
            .finally(() => done())
        })

        this.loyalty.registerCallback('login', (done) => {
          this.$router.push({ name: 'sayl-front-user.login' })
          done()
        })

        this.loyalty.registerCallback('subscribe', (done) => {
          this.$router.push({ name: 'sayl-front-user.signup' })
          done()
        })

        this.loyalty.registerCallback('way', (fqn, done, opts = {}) => {
          if(fqn === 'challenge') {
            this.$router.push({ name: 'sayl-front.challenges' })
            done()
          } else if(fqn === 'referral') {
            this.loyalty.focus('referral')
            done()
          } else if(fqn === 'birthday') {
            this.$user.user.birthdate = opts.birthdate
            
            this.$user.update({ })
              .then(() => done())
              .catch((e) => done(this.$t('resto.birthdate_validation_error', { errors: this.$basil.get(e, items) })))
          } else if(fqn === 'tiers') {
            this.loyalty.focus('tiers')
            done()
          } else {
            done()
          }
        })

        this.loyalty.registerCallback('history-more', (done) => {
          this.getHistory(this.pagination.currentPage + 1)
            .then(() => {
              this.loyalty.set('history', this.history.map((h) => {
                return {
                  amount: parseFloat(h.amount, 10).toFixed(2),
                  key: h.id,
                  title: this.getLabel(h.message),
                  date: h.created,
                  metadata: this.getMeta(h)
                }
              }))
            })
            .catch((e) => $console.error(e))
            .finally(() => done(this.pagination.total > this.pagination.currentPage * this.pagination.pageSize))
        })

        this.loyalty.registerCallback('referral-history-more', (done) => {
          this.getHistoryReferral(this.referralPagination.currentPage + 1)
            .then(() => {
              this.loyalty.set('referral-history', this.referralHistory.map((rh) => {
                let reward = this.$basil.get(rh, 'generatedEntity.type')
                if(reward === 'nft_transfer') {
                  reward = 'nft'
                }

                if(reward === 'credit') {
                  reward = 'loyalty'
                }

                return {
                  key: rh.id,
                  date: rh.created,
                  title: this.$t('resto.referral_type_' + rh.type),
                  reward: reward,
                  metadata: this.getMeta(rh)
                }
              }))
            })
            .catch((e) => $console.error(e))
            .finally(() => done(this.referralPagination.total > this.referralPagination.currentPage * this.referralPagination.pageSize))
        })

        this.loyalty.registerCallback('add-card', (token, done) => {
          this.$loyalty.unusedCard({ token: token })
            .then(() => {
              this.$loyalty.linkCard({ token: token })
                .then(() => this.getCards())
                .then(() => {
                  this.loyalty.set('cards', this.userCards)
                  done(null)
                })
                .catch((e) => {
                  let error =  this.$t(`resto.card_registration_card_no_token_error_message`)
                  done(error)
                })
            })
            .catch((e) => {
              let status = this.$basil.get(e, 'status', null)
              let error = this.$t(`resto.card_registration_card_${status}_error_message`)
              done(error)
            })
        })

        this.loyalty.registerCallback('wallet-pass', (done) => {
          this.$user.getTokenForPkPass({})
            .then((token) => {
              let id = this.$basil.get(this.$core, '_embed._embed._embed.id')
              window.location.assign(this.walletHref + `/${token}?embed_id=${id}`)
            })
            .catch((e) => $console.error(e))
            .finally(() => done())
        })

        this.loyalty.registerCallback('referral-activation', (done) => {
          this.$referral.activate()
            .then(() => {
              this.key++
              this.loyalty.set('referral', this.referral)
              done()
            })
            .catch((e) => done(e))
        })

        this.loyalty.registerCallback('referral-link', (action, done) => {
          this.getReferralLink(action)
            .then((url) => done(url))
            .catch(e => done(this.baseReferralUrl))
        })
      }

      setTimeout(() => {
        if(this.$route.meta.hasOwnProperty('action')) {
          switch(this.$route.meta.action) {
            case 'balance':
              this.loyalty.focus('how')
              break

            case 'cards':
              this.loyalty.focus('cards_add')
              break

            case 'vouchers':
              this.loyalty.focus('usage')
              break
          }
        }
      }, 100)
    },
  },

  mounted() {
    this.history = []
    this.pagination = {
      currentPage: 1,
      pageSize: 10,
      total: 0,
    },
    this.reset()
  },

  created() {
    if(this.isLogged && this.$route.name !== 'sayl-front-user.balance') {
      this.$router.replace({ name: 'sayl-front-user.balance' }).catch(() => {})
    } else if(!this.isLogged && this.$route.name !== 'sayl-front-user.loyalty') {
      this.$router.replace({ name: 'sayl-front-user.loyalty' }).catch(() => {})
    }
  },

  beforeDestroy() {
    const wait = (delay, action) => {
      setTimeout(() => this.loyalty ? action() : wait(delay, action), delay)
    }

    const destroy = () => {
      this.loyalty && this.loyalty.unmount()

      let page = document.getElementById('sayl-loyalty-page-resto')
      page && page.remove()

      let lpage = document.getElementById('sayl-loyalty-page')
      lpage && lpage.remove()
    }

    this.loyalty== null ? wait(100, destroy) : destroy()
  }
}
</script>
