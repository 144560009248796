<template>
  <!-- Signin and up -->
  <section class="ui-logger">
    <div class="ui-logger__article -signup">
      <div class="ui-logger__header">
        <div class="ui-logger__title">{{ $t(`resto.${context ? context + '_' : ''}signup_label`) }}</div>
        <div class="ui-logger__description">{{ $t(`resto.${context ? context + '_' : ''}signup_description`) }}</div>
      </div>

      <article class="ui-logger__content">
        <actions-button
          :appearance="$pepper.Appearance.PRIMARY"
          class="ui-logger__action"
          :href="{ name: 'sayl-front-user.signup' }"
          :size="$pepper.Size.S"
        >{{ $t('resto.signup_action') }}</actions-button>
      </article>
    </div>

    <div class="ui-logger__article -signin">
      <div class="ui-logger__header">
        <div class="ui-logger__title">{{ $t(`resto.${context ? context + '_' : ''}signin_label`) }}</div>
        <div class="ui-logger__description">{{ $t(`resto.${context ? context + '_' : ''}signin_description`) }}</div>
      </div>

      <article class="ui-logger__content">
        <actions-button
          :appearance="$pepper.Appearance.DEFAULT"
          class="ui-logger__action -signin"
          :href="{ name: 'sayl-front-user.login' }"
          :size="$pepper.Size.S"
        >{{ $t('resto.signin_action') }}</actions-button>
      </article>
    </div>
  </section>
</template>

<script>
// At some points this should disapear
export default {
  name: 'UiLogger',

  props: {
    context: {
      type: String,
      default: 'loyalty'
    }
  }
}
</script>
